import React, { useEffect } from "react";

const Pixels = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.location.href =
        "https://www.figma.com/file/6Lw9Z2EvWeBr35rLrzQARQ/Hands-On?node-id=0%3A1";
    }
  }, []);

  return <p>redirecting...</p>;
};

export default Pixels;
